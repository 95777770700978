const verbiage = require('../constants/verbiage.js')

const validateResetPinForm = (values) => {
  let errors = {}
  const employeeNumber = values.employeeNumber
  const sellingPin = values.sellingPin
  const confirmSellingPin = values.confirmSellingPin
  if (sellingPin.length === 0) {
    errors.sellingPin = 'Required'
  } else {
    const validationError = module.exports.validateSellingPin(
      sellingPin,
      employeeNumber
    )
    if (validationError) {
      errors.sellingPin = validationError
    }
  }
  if (confirmSellingPin.length === 0) {
    errors.confirmSellingPin = 'Required'
  } else if (
    sellingPin !== confirmSellingPin ||
    sellingPin.length !== confirmSellingPin.length
  ) {
    errors.confirmSellingPin = 'Selling pin not matching'
  }
  return errors
}

const validateSellingPin = (sellingPin, employeeNumber) => {
  if (!/^[0-9]{6}$/.test(sellingPin)) {
    return verbiage.messages.PIN_MSG_1
  } else if (findMaximumRepeating(sellingPin) > 2) {
    return verbiage.messages.PIN_MSG_3
  } else if (employeeNumber.indexOf(sellingPin) > -1) {
    return verbiage.messages.PIN_MSG_5
  } else if (sellingPin === '123456' || sellingPin === '654321') {
    return verbiage.messages.PIN_MSG_6
  }
  return null
}

const findMaximumRepeating = (str) => {
  let max = 0
  if (!str) return max
  for (let start = 0, end = 1; end < str.length; ) {
    if (str[end] === str[start]) {
      if (max < end - start + 1) {
        max = end - start + 1
      }
      end++
    } else {
      start = end
    }
  }
  return max
}

module.exports = {
  validateResetPinForm,
  validateSellingPin,
  findMaximumRepeating
}
