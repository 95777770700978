import { useOktaAuth } from '@okta/okta-react'
import React from 'react'
import { Button, Grid, Image, Divider } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import logo from '../images/nordstrom.png'

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const history = useHistory()

  const resetPIN = async () => {
    if (!authState.isAuthenticated) {
      // If user is not assigned to App, app will crash instead of rendering error.
      // https://github.com/okta/okta-react/issues/76
      oktaAuth.signInWithRedirect('resetpin')
    } else {
      history.push('/resetpin')
    }
  }

  if (authState.isPending) {
    return <div>Loading...</div>
  }
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={3}></Grid.Column>
        <Grid.Column width={10}>
          <Image src={logo} size="medium" centered={true} data-testid="logo" />
          <Divider centered="true" />
          <div className="alignCenter">
            <Button data-testid="reset-pin-button" primary onClick={resetPIN}>
              RESET PIN
            </Button>
          </div>
        </Grid.Column>
        <Grid.Column width={3}></Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
export default Home
