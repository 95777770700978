const { REACT_APP_ISSUER, REACT_APP_CLIENT_ID } = process.env
const REDIRECT_URI = `${window.location.origin}/login/callback`

const config = {
  oktaAuthOptions: {
    clientId: REACT_APP_CLIENT_ID,
    issuer: REACT_APP_ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'employee_number'],
    pkce: true,
    tokenManager: {
      storage: 'sessionStorage'
    }
  }
}

export default config
