import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect, useState } from 'react'
import { Grid, Message, Loader, Label } from 'semantic-ui-react'

const Logout = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const [message, setMessage] = useState('Logged Out')
  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.signOut({
        postLogoutRedirectUri: `${window.location.origin}/logout`
      })
    }
  })

  useEffect(() => {
    const cached = sessionStorage.getItem('logoutMessage')
    if (cached) {
      setMessage(cached)
    }
    return () => {
      sessionStorage.removeItem('logoutMessage')
    }
  }, [])

  if (authState.isAuthenticated) {
    return <Loader active inline="centered" size="medium" />
  }

  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={12}>
            <Message>
              <Message.Header>{message}</Message.Header>
              <br />
              <Label basic>You are now logged out.</Label>
            </Message>
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default Logout
