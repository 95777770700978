import React, { useRef, useState, useEffect } from 'react'
import IdleTimer from 'react-idle-timer'
import { Modal, Button, Label } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

const IdleSessionPrompt = ({ maxIdleMinutes, cancelPromptSeconds }) => {
  const history = useHistory()

  const idleTimerRef = useRef(null)
  const sessionTimeoutRef = useRef(null)
  const sessionIntervalRef = useRef(null)
  const [openModel, setOpenModel] = useState(false)
  const [timeUntilLogout, setTimeUntilLogout] = useState(0)

  const onIdle = () => {
    setOpenModel(true)
    setTimeUntilLogout(cancelPromptSeconds)
    sessionTimeoutRef.current = setTimeout(logout, cancelPromptSeconds * 1000)
  }
  const stayActive = () => {
    setOpenModel(false)
    clearTimeout(sessionTimeoutRef.current)
    clearInterval(sessionIntervalRef.current)
    setTimeUntilLogout(0)
  }
  const logout = () => {
    setOpenModel(false)
    clearTimeout(sessionTimeoutRef.current)
    clearInterval(sessionIntervalRef.current)
    history.replace('/logout')
  }
  useEffect(() => {
    if (openModel) {
      sessionIntervalRef.current = setInterval(() => {
        setTimeUntilLogout(timeUntilLogout - 1)
      }, 1000)
    }
    return () => clearInterval(sessionIntervalRef.current)
  })
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={maxIdleMinutes * 1000 * 60}
        onIdle={onIdle}
      />
      <Modal size="tiny" centered={false} open={openModel} onClose={stayActive}>
        <Modal.Content>
          <div className="m-size">
            Your session will expire in{' '}
            <Label size="large" color="yellow">
              {timeUntilLogout} seconds
            </Label>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={logout}>
            Logout
          </Button>
          <Button positive onClick={stayActive}>
            Stay Active
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}
export default IdleSessionPrompt
