import { useOktaAuth } from '@okta/okta-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Menu, Dropdown } from 'semantic-ui-react'

const Navbar = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const history = useHistory()
  const logout = async () => {
    if (authState.isAuthenticated) {
      await oktaAuth.signOut()
    } else {
      history.replace('/')
    }
  }

  return (
    <div>
      <Menu inverted>
        <Container>
          <Menu.Item header onClick={logout} className="l-size">
            Selling PIN Maintenance
          </Menu.Item>
          <Menu.Menu position="right">
            {authState.isAuthenticated && (
              <div style={{ marginTop: '.2em' }}>
                <Dropdown
                  item
                  text={
                    authState.isAuthenticated && authState.idToken.claims.email
                  }
                  className="email"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        history.replace('/logout')
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </Menu.Menu>
        </Container>
      </Menu>
    </div>
  )
}
export default Navbar
