import axios from 'axios'
import slugid from 'slugid'
import verbiage from '../constants/verbiage'

export const resetPin = async (newPin, idToken) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/reset/pin',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Nord-Client-Id': process.env.REACT_APP_NORD_APP_ID,
        'Nord-Request-Id': slugid.v4()
      },
      data: {
        newPin: newPin
      }
    })
    return res.data.message
  } catch (err) {
    if (err.response && err.response.data.message) {
      return err.response.data.message
    } else {
      return verbiage.messages.PIN_RESET_FAILURE
    }
  }
}
