const messages = {
  PIN_REQ: 'Selling PIN requirements:',
  PIN_MSG_1: 'PIN must be 6 digits',
  PIN_MSG_2: 'PIN must contain numbers only',
  PIN_MSG_3: 'PIN must not use repeating numbers',
  PIN_MSG_4: 'PIN must not have been used in last 6 months',
  PIN_MSG_5: 'PIN must not include parts of your employee number',
  PIN_MSG_6: 'PIN must not be 123456 or 654321',
  PIN_RESET_SUCCESS: 'Selling PIN successfully changed',
  PIN_RESET_FAILURE:
    'Pin could not be changed. Please contact your administrator.',
  SUSPENDED_ACCOUNT:
    'Reset is not allowed at this time. Try again after 30 minutes.'
}

const labels = {
  EMPLOYEE_NUMBER: 'Employee Number',
  SELLING_PIN: 'Selling Pin',
  CONFIRM_SELLING_PIN: 'Confirm Selling Pin'
}

module.exports = {
  labels,
  messages
}
