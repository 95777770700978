import { useOktaAuth } from '@okta/okta-react'
import React, { useState, useEffect } from 'react'
import {
  Button,
  Form,
  Grid,
  Loader,
  List,
  Icon,
  Header,
  Message,
  Segment,
  Label
} from 'semantic-ui-react'
import { validateResetPinForm } from '../utils/validation'
import { resetPin } from '../services/pin-service'
import IdleSessionPrompt from './IdleSessionPrompt'
import { useHistory } from 'react-router-dom'
import verbiage from '../constants/verbiage'

const ResetPin = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const [values, setValues] = useState({
    employeeNumber: '',
    sellingPin: '',
    confirmSellingPin: ''
  })
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const history = useHistory()

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setErrors(validateResetPinForm(values))
    setIsSubmitting(true)
  }
  useEffect(() => {
    if (authState.isAuthenticated) {
      setValues((values) => ({
        ...values,
        employeeNumber: authState.idToken.claims.employee_number
      }))
    }
  }, [authState])
  useEffect(() => {
    const submitForm = async () => {
      const idToken = await oktaAuth.getIdToken()
      if (idToken) {
        let message
        message = await resetPin(`${values.sellingPin}`, idToken)
        sessionStorage.setItem('logoutMessage', message)
        setIsSubmitting(false)
        history.replace('/logout')
      }
    }
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitForm()
    } else {
      setIsSubmitting(false)
    }
  }, [errors, oktaAuth, isSubmitting, values, history])

  return (
    <div>
      <Grid>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={8}>
            <Segment color="blue">
              <Header as="h3">
                <Icon name="lock" color="red" />
                Selling PIN Change
              </Header>
              <Message>
                <Label basic size="medium">
                  {verbiage.messages.PIN_REQ}
                </Label>
                <List bulleted size="medium">
                  <List.Item>{verbiage.messages.PIN_MSG_1}</List.Item>
                  <List.Item>{verbiage.messages.PIN_MSG_2}</List.Item>
                  <List.Item>{verbiage.messages.PIN_MSG_3}</List.Item>
                  <List.Item>{verbiage.messages.PIN_MSG_4}</List.Item>
                </List>
              </Message>
              <Form onSubmit={handleSubmit} className="selling-pin-box">
                <Form.Field inline>
                  <Label className="m-size" color="brown">
                    {verbiage.labels.EMPLOYEE_NUMBER}
                  </Label>
                  <label className="m-size">{values.employeeNumber}</label>
                </Form.Field>
                <Form.Field>
                  <label className="m-size">
                    {verbiage.labels.SELLING_PIN}
                  </label>
                  <input
                    placeholder="Enter 6 digit pin"
                    name="sellingPin"
                    value={values.sellingPin}
                    onChange={(event) => handleChange(event)}
                    type="password"
                    data-testid="selling-pin"
                  />
                  {errors.sellingPin && (
                    <span className="error">{errors.sellingPin}</span>
                  )}
                </Form.Field>
                <Form.Field>
                  <label className="m-size">
                    {verbiage.labels.CONFIRM_SELLING_PIN}
                  </label>
                  <input
                    placeholder="Re-enter 6 digit pin"
                    name="confirmSellingPin"
                    value={values.confirmSellingPin}
                    onChange={(event) => handleChange(event)}
                    type="password"
                    data-testid="confirm-selling-pin"
                  />
                  {errors.confirmSellingPin && (
                    <span className="error">{errors.confirmSellingPin}</span>
                  )}
                </Form.Field>
                <Form.Field>
                  <Button type="submit" primary>
                    Reset Pin
                  </Button>
                  <br />
                  {errors.message && (
                    <span className="error">{errors.message}</span>
                  )}
                </Form.Field>
              </Form>
              {isSubmitting && (
                <Loader
                  active
                  inline="centered"
                  size="medium"
                  className="loader"
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <IdleSessionPrompt maxIdleMinutes={1} cancelPromptSeconds={30} />
      </Grid>
    </div>
  )
}

export default ResetPin
