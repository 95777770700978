import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { OktaAuth } from '@okta/okta-auth-js'
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react'
import { Container } from 'semantic-ui-react'
import config from './utils/config'
import Home from './components/Home'
import Navbar from './components/Navbar'
import ResetPin from './components/ResetPin'
import Logout from './components/Logout'

const oktaAuth = new OktaAuth(config.oktaAuthOptions)

const App = () => {
  const history = useHistory()
  const restore = async (_, originalUri) => {
    history.replace('/resetpin')
  }
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restore}>
      <Navbar />
      <Container style={{ marginTop: '2em' }}>
        <Switch>
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute path="/resetpin" component={ResetPin} />
          <Route path="/logout" component={Logout} />
          <Route path="/" component={Home} />
        </Switch>
      </Container>
    </Security>
  )
}
export default App
